;(function (window, document, undefined) {
  // the main three.js components
  var camera, scene, renderer,
  // to keep track of the mouse position
  mouseX = 0, mouseY = 50,
  // an array to store our particles in
  particles = [];

  var canvas = document.getElementById('field');

  var pause = JSON.parse(localStorage.getItem('pause'));
  if ( pause === null) {
    pause = false;
  }

  var interval = null;

  // let's get going!
  init();

  function init() {

  // Camera params :
  // field of view, aspect ratio for render output, near and far clipping plane.
  camera = new THREE.PerspectiveCamera(80, canvas.offsetWidth / canvas.offsetHeight, 1, 4000 );

  // move the camera backwards so we can see stuff!
  // default position is 0,0,0.
  camera.position.z = 1000;
  // the scene contains all the 3D object data
  scene = new THREE.Scene();

  // camera needs to go in the scene
  scene.add(camera);

  // and the CanvasRenderer figures out what the
  // stuff in the scene looks like and draws it!
  renderer = new THREE.CanvasRenderer();
  renderer.setSize( canvas.offsetWidth, canvas.offsetHeight );

  // the renderer's canvas domElement is added to the body
  canvas.appendChild( renderer.domElement );
  makeParticles();

  // add the mouse move listener
  document.addEventListener( 'mousemove', onMouseMove, false );

  var pause_button = document.createElement('a');
  pause_button.style = 'z-index: 5; color: white; position: absolute; right: 0; bottom: 0; cursor: pointer;';
  var icon = document.createElement('i');
  var state = pause ? 'play' : 'pause';
  icon.classList.add(state);
  icon.classList.add('icon');
  pause_button.appendChild(icon);
  pause_button.onclick = function() {
    tooglePause();
    if (pause) {
      $(this).title = "Lancer l'animation";
      $(this).html('<i class="play icon"></i>');
    } else {
      $(this).title = "Arrêter l'animation";
      $(this).html('<i class="pause icon"></i>');
    }
  };
  canvas.append(pause_button);

  // render 30 times a second (should also look
  // at requestAnimationFrame)

  if (!pause) {
    startAnimation();
  } else {
    // Render just one frame
    update();
  }
  }

  function startAnimation() {
    interval = setInterval(update,1000/30);
  }

  function stopAnimation() {
    clearInterval(interval);
    document.getElementById('home_image').classList.remove('shake');
    document.getElementById('home_image').classList.remove('shake-constant');
    document.getElementById('home_text').classList.remove('shake');
    document.getElementById('home_text').classList.remove('shake-constant');
  }

  function tooglePause() {
    if (pause) {
      startAnimation();
    } else {
      stopAnimation();
    }
    pause = !pause;
    console.log(pause);
    localStorage.setItem('pause', JSON.stringify(pause));
  }


  // the main update function, called 30 times a second
  function update() {

  if (window.scrollTop > canvas.offsetHeight) {
    return;
  }

  updateParticles();
  // and render the scene from the perspective of the camera
  renderer.render( scene, camera );
  }
  // creates a random field of Particle objects

  function makeParticles() {

  var particle, material;
  // we're gonna move from z position -1000 (far away)
  // to 1000 (where the camera is) and add a random particle at every pos.
  for ( var zpos= -1000; zpos < 1000; zpos+=20 ) {

  	// we make a particle material and pass through the
  	// colour and custom particle render function we defined.
  	material = new THREE.ParticleCanvasMaterial({
      color: 0xffffff,
      opacity: 0,
      program: particleRender,
    });
  	// make the particle
  	particle = new THREE.Particle(material);

  	// give it a random x and y position between -500 and 500
  	particle.position.x = Math.random() * 4000 - 2000;
  	particle.position.y = Math.random() * 1000 - 500;

  	// set its z position
  	particle.position.z = zpos;

    particle.rotation.z = Math.random() * 360 * Math.PI / 180;

  	// scale it up a bit
  	particle.scale.x = particle.scale.y = 10;

  	// add it to the scene
  	scene.add( particle );

  	// and to the array of particles.
  	particles.push(particle);
  }

  }

  // there isn't a built in circle particle renderer
  // so we have to define our own.
  function particleRender( context ) {

  // // we get passed a reference to the canvas context
  // context.beginPath();
  // // and we just have to draw our shape at 0,0 - in this
  // // case an arc from 0 to 2Pi radians or 360º - a full circle!
  // context.arc( 0, 0, 1, 0,  Math.PI * 2, true );
  // context.fill();

  var image = document.getElementById("source");
  context.drawImage(image, 0, 0, 800, 800, 0, 0, 80, 80);

  };

  // moves all the particles dependent on mouse position

  function updateParticles() {

  // iterate through every particle
  for(var i=0; i<particles.length; i++) {

  	particle = particles[i];

    var inc = 3;
    if (mouseY <= canvas.offsetHeight) {
      inc += mouseY * 0.1;
    }

  	// and move it forward dependent on the mouseY position.
  	particle.position.z += inc;
    particle.material.opacity = (particle.position.z/1000 + 1.5)/2;

    if(inc > 40) {
      document.getElementById('home_image').classList.add('shake');
      document.getElementById('home_image').classList.add('shake-constant');
      document.getElementById('home_text').classList.add('shake');
      document.getElementById('home_text').classList.add('shake-constant');
    } else {
      document.getElementById('home_image').classList.remove('shake');
      document.getElementById('home_image').classList.remove('shake-constant');
      document.getElementById('home_text').classList.remove('shake');
      document.getElementById('home_text').classList.remove('shake-constant');
    }

  	// if the particle is too close move it to the back
  	if(particle.position.z>1100) particle.position.z-=2000;

  }

  }

  // called when the mouse moves
  function onMouseMove( event ) {
  // store the mouseX and mouseY position
  mouseX =  event.pageX - canvas.offsetLeft;
  mouseY = event.pageY - canvas.offsetTop;
  }
})( window, document );
